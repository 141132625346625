#frog-image {
    position: absolute;
    bottom: 0;
    width: 400px;
    left: 0;
    z-index: -1;
}

#add-activity-button {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
}

.content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}